import * as React from "react"
import { Container, Button, Col } from "react-bootstrap"

import { GatsbyImage } from "gatsby-plugin-image"
import { StaticQuery, graphql, Link } from "gatsby"


 



 
function FeaturedTours({data}) {

    return (
        <StaticQuery 
        query={graphql`
        query Tours{
            allGraphCmsTours {
                edges {
                  node {
                    id
                    slug
                    tourTitle
                    tourStatus
                    displayPrice
                    tourPrice{
                      html
                      text
                      raw
                    }
                   
                   
                    tourDescription {
                      html
                      text
                      raw
                    }
                    tourImage {
                      id
                      imageAltText
                      
                      galleryImage {
                        gatsbyImageData(placeholder: BLURRED, quality: 60)
                      }
                    }
                  }
                }
              }
           }                     
        `}
                
        render={data => (
        
    <div className="tours">
        <div className="welcome-bg">

        </div>
        <div className="wrapper">
          <div className="row-header">
           
            <div className="title">
                  <span>Let's Take a Dive Into</span>
                  <h1>Our Featured Tours</h1>
                  
            </div>
            
           
           
            
          </div>
       

         <div className="gallery">
          
            <div className="image-gallery">
            
              <div className="photos">
             {data.allGraphCmsTours .edges.map(({ node: country }) => (
              <Link  to={`/${country.slug}`}>
                <div  key={country.id}>
                {country.tourStatus.includes("Featured")
                 ?
                 
                   <div className="main-content"  key={country.id}>
                        <div className="data-wrapper">
                        
                            <div className="estate-type">
                                <div className="image-field">
                                    <GatsbyImage image={country.tourImage.galleryImage.gatsbyImageData}
                                        quality={60}
                                        formats={["auto", "webp", "avif", "png", "jpg"]}
                                        alt={country.tourImage.imageAltText}
                                    />
                                  
                                  <div className="overlay">
                                  <div className="overlay-2"></div>
                                        <div className="book">
                                          <a href="">View Tour</a>
                                        </div>
                                    <div className="content">
                                        <Container> 
                                        <div className="block">
                                               
                                        <div className="desc"> <p>{country.displayPrice}</p>
                                            <h2>{country.tourTitle}</h2>
                                       
                                          <div dangerouslySetInnerHTML={{ __html:country.tourDescription.html}} />
                                         
                                         
                                          </div>       
                                           
                                       </div>
                                        </Container>
                                    </div>
                                    
                                    
                                    </div>


                                </div>
                                {/*<div className="desc-field">
                                    <div className="content">
                                        <Container> 
                                        <div className="block">
                                               
                                          <div><h2>{country.tourTitle}</h2></div>
                                        
                                                  
                                           
                                       </div>
                                        </Container>
                                    </div>
             </div>*/}
                            </div> 
                     

                        </div>
                        
                    </div> 
                   :
                   <></>
                   }
                </div>
                </Link>
                ))}
                </div>
            
            </div>
           </div>
        </div>
        <a href="/all-tours"><button id="primary-button" type="button" >View All Tours</button></a>
	</div>
    

    )}
      />
    );
}


export default FeaturedTours;


